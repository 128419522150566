@charset "UTF-8";
.article-details {
  padding-block: 35px 60px;
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1.8;
  letter-spacing: 1px;
}

.article-details a[class^="link"] {
  color: #0094c8;
  text-decoration: none;
}

.article-details a[class^="link"]:hover, .article-details a[class^="link"]:focus {
  text-decoration: none;
  outline: none;
}

.article-details__container {
  padding-inline: 20px;
  margin-inline: auto;
  width: 100%;
  max-width: 840px;
}

.article-details__container-2 {
  width: 90%;
  max-width: 1020px;
  margin: 0 auto;
}

.article-details__header p {
  font-size: 11px;
  line-height: 1;
  color: #666;
  margin-block: 0 10px;
}

.article-details__header h1 {
  font-size: 18px;
  font-family: "Founders Grotesk", sans-serif;
  line-height: 1.4;
  margin-block: 0 25px;
}

.article-details__header img {
  display: block;
  width: 100%;
  max-width: 1020px;
  aspect-ratio: 7/4;
  margin: 0 auto 32px;
}

.article-details__content h2 {
  font: 700 18px/1.6 "Founders Grotesk", sans-serif !important;
  letter-spacing: 2px;
  margin-block: 50px 35px;
  position: relative;
}

.article-details__content h2::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -20px;
  width: 5px;
  height: 100%;
  background-color: #000;
}

.article-details__content p {
  line-height: 1.8;
  margin-block: 0 25px;
}

.article-details__content p a {
  color: currentColor;
}

.article-details__content p.p-img-left, .article-details__content p.p-img-right {
  display: grid;
  gap: 25px;
}

.article-details__content p.p-img-left-005, .article-details__content p.p-img-right-005 {
  display: grid;
  gap: 25px;
  grid-template-areas: "image" "text";
}

.article-details__content p.p-img-left-005 img, .article-details__content p.p-img-right-005 img {
  grid-area: image;
  max-width: 227px;
}

.article-details__content p.p-img-left-005 span, .article-details__content p.p-img-right-005 span {
  grid-area: text;
}

@media (min-width: 768px) {
  .article-details__content p.p-img-left-005, .article-details__content p.p-img-right-005 {
    grid-template-areas: "text image";
    grid-template-columns: 1fr auto;
    align-items: center;
  }
  .article-details__content p.p-img-left-005 img, .article-details__content p.p-img-right-005 img {
    grid-area: image;
    max-width: 333px;
  }
}

.article-details__content p.p-img-left-004, .article-details__content p.p-img-right-004 {
  gap: 25px;
}

@media (min-width: 768px) {
  .article-details__content p.p-img-left-004, .article-details__content p.p-img-right-004 {
    gap: 48px;
  }
}

.article-details__content p.p-img-small img {
  max-width: 66%;
}

.article-details__content p.p-align-center {
  align-items: center;
}

.article-details__content img {
  display: block;
  width: 100%;
  margin-inline: auto;
}

.article-details__content ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.article-details__content ol li h3 {
  display: flex;
  font: 700 16px/1.5 "Founders Grotesk", sans-serif;
  margin-block: 45px 16px;
}

.article-details__content ol li h3 span {
  flex-shrink: 0;
  display: inline-flex;
  color: #b47e00;
  padding-right: 16px;
  margin-right: 16px;
  border-right: 1px solid #7d7d7d;
}

.article-details__content a:hover, .article-details__content a:focus {
  text-decoration: none;
  outline: none;
}

.article-details__content a.link-arrow-left, .article-details__content a.link-arrow-right {
  position: relative;
}

.article-details__content a.link-arrow-left::before, .article-details__content a.link-arrow-right::before {
  content: "";
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  border-style: solid;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid #0094c8;
  border-right: 0;
}

.article-details__content a.link-arrow-left {
  padding-left: 15px;
}

.article-details__content a.link-arrow-left::before {
  left: 0;
}

.article-details__content a.link-arrow-right {
  padding-right: 15px;
}

.article-details__content a.link-arrow-right::before {
  right: 0;
}

.article-details__intro {
  padding: 70px 24px 24px;
  border: 1px solid #000;
  position: relative;
  margin-top: 48px;
}

.article-details__intro-grid {
  display: grid;
  gap: 24px;
}

.article-details__intro h3,
.article-details__intro h4 {
  text-align: center;
  font: 700 16px/1 "Founders Grotesk", sans-serif;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  background-color: #fff;
  padding-inline: 16px;
  margin-block: 0;
}

.article-details__related {
  position: relative;
  border-top: 1px solid #e3e3e3;
  margin-block: 60px 48px;
  padding: 24px 20px 0;
}

.article-details__related-grid {
  display: grid;
  gap: 24px;
}

.article-details__related h3 {
  text-align: center;
  font: 700 16px/1 "Founders Grotesk", sans-serif;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  background-color: #fff;
  padding-inline: 16px;
  white-space: nowrap;
}

.article-details__sns {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  padding-block: 50px 40px;
}

.article-details__sns li {
  display: flex;
}

.article-details__sns li a {
  display: inline-flex;
}

.article-details__sns li a svg {
  width: 22px;
  height: 22px;
}

.article-details__sns li a svg path {
  fill: #999;
}

.article-details__sns li:last-child a svg {
  width: 24px;
  height: 24px;
}

.article-details__footer {
  border-top: 1px solid #e3e3e3;
  padding: 20px 20px 0;
  max-width: 1060px;
  margin-inline: auto;
}

.article-details__footer a {
  color: #000;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-size: 15px;
  outline: none;
  transition: opacity 0.3s ease;
}

.article-details__footer a svg {
  width: 18px;
  height: 18px;
}

.article-details__footer a svg path {
  fill: #999;
}

.article-details__footer a:hover, .article-details__footer a:focus {
  color: #000;
  text-decoration: none;
  opacity: 0.6;
  outline: none;
}

.article-details__pins {
  background-color: #f7f7f7;
  list-style: none;
  padding: 24px 20px;
  margin-block: 25px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.article-details__pins li {
  display: flex;
  position: relative;
  padding-left: 2em;
}

.article-details__pins li::before {
  content: "📌";
  position: absolute;
  top: 2px;
  left: 0;
  font-size: smaller;
}

.article-details__card {
  border: 1px solid #e3e3e3;
  padding: 24px;
  display: grid;
  grid-template-columns: 4fr 7fr auto;
  align-items: center;
  gap: 24px;
  color: #000;
  transition: opacity 0.3s ease;
}

.article-details__card p {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  margin: 0;
}

.article-details__card p small {
  font-weight: normal;
  font-size: smaller;
}

.article-details__card svg {
  width: 15px;
  height: 28px;
}

.article-details__card:is(a):hover {
  color: #000;
  opacity: 0.6;
}

.article-details__offer {
  background-color: #f7f7f7;
  padding: 24px 24px 48px;
}

.article-details__offer h4 {
  font-size: 14px;
  font-weight: 700 !important;
  line-height: 1.8;
  border-bottom: 1px solid #dee2e6;
  text-align: center;
  margin-block: 0 24px;
  padding-bottom: 8px;
}

@media (max-width: 767px) {
  .article-details__content p.p-img-single {
    margin-inline: -20px;
  }
  .article-details__intro img, .article-details__related img {
    max-width: 50%;
    margin-inline: auto;
  }
  .article-details__related {
    margin-inline: -20px;
  }
  .article-details__pins {
    margin-inline: -20px;
  }
}

@media (min-width: 768px) {
  .article-details__content p.p-img-left {
    grid-template-columns: 4fr 6fr;
  }
  .article-details__content p.p-img-right {
    grid-template-columns: 6fr 4fr;
  }
  .article-details__content .button-group {
    max-width: 460px;
    margin-inline: auto;
  }
  .article-details__content .button-group--2 {
    max-width: 676px;
  }
  .article-details__intro {
    padding: 70px 48px 48px;
    margin-top: 48px;
  }
  .article-details__intro-2 {
    margin-block: 80px;
  }
  .article-details__intro-grid {
    grid-template-columns: 4fr 6fr;
    align-items: center;
    gap: 48px;
  }
  .article-details__intro-grid-2 {
    grid-template-columns: 4fr 6fr;
    align-items: center;
    gap: 48px;
  }
  .article-details__intro-grid-2 img {
    max-width: 58.333333%;
    margin-inline: auto;
    height: auto;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .article-details__intro-grid-2 img {
    max-width: 234px;
  }
}

@media (min-width: 768px) {
  .article-details__related {
    padding: 70px 48px 48px;
    margin-top: calc(48px * 2);
  }
  .article-details__related-grid {
    grid-template-columns: 4fr 6fr;
    align-items: center;
    gap: 48px;
  }
  .article-details__card {
    padding: 40px;
  }
}

@media (min-width: 1024px) {
  .article-details {
    padding-block: 50px 120px;
    font-size: 16px;
    line-height: 2;
  }
  .article-details__header p {
    font-size: 14px;
  }
  .article-details__header h1 {
    font-size: 26px;
    margin-bottom: 40px;
  }
  .article-details__content h2 {
    font-size: 26px !important;
    margin-block: 70px 35px;
  }
  .article-details__content p.p-img-small img {
    max-width: 42%;
  }
  .article-details__content ol li h3 {
    font-size: 20px;
  }
  .article-details__intro h3,
  .article-details__intro h4 {
    font-size: 20px;
  }
  .article-details__related h3 {
    font-size: 20px;
  }
  .article-details__sns {
    padding-block: 80px 50px;
  }
  .article-details__pins {
    padding: 32px 40px;
  }
  .article-details__card {
    padding: 48px;
  }
  .article-details__offer h4 {
    font-size: 15px;
  }
}

@media (min-width: 1440px) {
  .article-details__footer {
    padding-inline: 0;
  }
}

.article-details__button-group-2 {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-block: 45px 0;
}

@media (min-width: 768px) {
  .article-details__button-group-2 {
    margin-right: -7.5px;
    margin-left: -7.5px;
  }
}

.article-details__button-group-2-warp {
  width: 100%;
  padding: 15px;
  max-width: 100%;
  position: relative;
  background-color: black;
  min-height: 50px;
  line-height: 1;
  text-align: center;
  border: 1px solid transparent;
  transition-duration: 0.5s;
  cursor: pointer;
  color: white !important;
}

@media (min-width: 768px) {
  .article-details__button-group-2-warp {
    max-width: 33.333333%;
  }
}

.article-details__button-group-2-warp a {
  font-size: 15px;
  color: white;
}

.article-details__button-group-2-warp:hover {
  background-color: #999 !important;
  color: white !important;
}

.article-details__button-group-3 {
  display: flex;
  justify-content: center;
  padding: 15px;
  background-color: black;
  width: 100%;
  line-height: 1;
  min-height: 50px;
  border: 1px solid transparent;
  transition-duration: 0.5s;
  cursor: pointer;
  margin: 0 auto;
  color: white !important;
}

@media (min-width: 768px) {
  .article-details__button-group-3-2 {
    max-width: 41.666666%;
  }
}

@media (min-width: 768px) {
  .article-details__button-group-3 {
    width: 50%;
  }
}

.article-details__button-group-3 a {
  color: white;
  font-size: 14px;
  font-weight: 500;
  border-width: 0;
  width: 100%;
  text-align: center;
}

.article-details__button-group-3:hover {
  background-color: #999 !important;
  color: white !important;
}

.article-details__button-group-4 {
  display: flex;
  justify-content: center;
  padding-top: 25px;
}

.article-details__button-group-4-warp {
  width: 100%;
  padding: 15px;
  position: relative;
  background-color: black;
  min-height: 50px;
  line-height: 1;
  text-align: center;
  border: 1px solid transparent;
  transition-duration: 0.5s;
  cursor: pointer;
  color: white !important;
}

@media (min-width: 768px) {
  .article-details__button-group-4-warp {
    max-width: 58.333333%;
  }
}

.article-details__button-group-4-warp a {
  font-size: 15px;
  color: white;
}

.article-details__button-group-4-warp:hover {
  background-color: #999 !important;
  color: white !important;
}

.article-details__content-004-005__container-list {
  margin-inline: auto;
  width: 100%;
}

@media (min-width: 768px) {
  .article-details__content-004-005__container-list {
    max-width: 840px;
    padding-inline: 20px;
  }
}

.article-details__content-004-005__symptoms {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 48px;
  padding: 1.5rem;
}

.article-details__content-004-005__symptoms .symptom-item {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

@media (min-width: 768px) {
  .article-details__content-004-005__symptoms .symptom-item {
    align-items: center;
  }
}

.article-details__content-004-005__symptoms .symptom-item__icon {
  flex-shrink: 0;
  font-size: 18px;
  line-height: 1.5;
  margin-top: 2px;
}

.article-details__content-004-005__symptoms .symptom-item__text {
  color: black;
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .article-details__content-004-005__symptoms .symptom-item__text {
    font-size: 16px;
  }
}

.article-details__content-004-005__symptoms-grid {
  background-color: #f7f7f7;
  display: grid;
  padding: 1.5rem 1rem 0.5rem 1rem;
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  .article-details__content-004-005__symptoms-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.article-details__content-004-005__symptoms-grid p {
  padding-bottom: 8px;
  color: black;
  font-size: 14px;
}

@media (min-width: 768px) {
  .article-details__content-004-005__symptoms-grid p {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .article-details__content-004-005__symptoms {
    padding: 24px 40px;
  }
}

.article-details__content-004-005__symptoms h4 {
  font-size: 14px;
  font-weight: 700 !important;
  line-height: 1.8;
  border-bottom: 1px solid #dee2e6;
  text-align: center;
  margin-block: 0 24px;
  padding-bottom: 8px;
}

.article-details__content-004-005__symptoms p {
  color: black;
  margin-block: 0;
  padding-bottom: 8px;
  font-size: 14px;
}

@media (min-width: 768px) {
  .article-details__content-004-005__symptoms p {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 16px;
  }
}

.article-details__content-004-005__flex-content {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

@media (min-width: 768px) {
  .article-details__content-004-005__flex-content {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: start;
    align-self: start;
    gap: 0;
  }
}

.article-details__content-004-005__flex-content p {
  color: black;
  padding: 0;
  margin: 0;
}

.article-details__content-004-005__flex-content-1 {
  max-width: 100%;
  padding-right: 0;
}

@media (min-width: 768px) {
  .article-details__content-004-005__flex-content-1 {
    max-width: 58.333333%;
    padding-right: 3rem !important;
  }
}

.article-details__content-004-005__flex-content-2 {
  max-width: 58.333333%;
  padding: 8px 0 0 0;
}

@media (min-width: 768px) {
  .article-details__content-004-005__flex-content-2 {
    max-width: 41.666666%;
    padding: 0;
  }
}

.article-details__content-004-005__list {
  margin: 0 !important;
  font-size: 14px;
  line-height: 1.8;
}

.article-details__content-004-005__list-2 {
  margin: 0px 0px 24px;
  padding: 0;
}

@media (min-width: 768px) {
  .article-details__content-004-005__list-2 {
    padding: 8px 24px 0px;
  }
}

.article-details__content-004-005__list-2 span {
  background-color: #343a40;
  color: white;
  width: 1.4em;
  height: 1.4em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 1rem !important;
}

@media (min-width: 768px) {
  .article-details__content-004-005__list {
    font-size: 16px;
    line-height: 2 !important;
  }
}

.article-details__content-004-005__list p {
  display: flex;
  align-items: center;
  color: black;
}

.article-details__content-004-005__list span {
  background-color: #343a40;
  color: white;
  width: 1.4em;
  height: 1.4em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 1rem !important;
}

.article-details__content-004-005__mb-25 {
  margin-bottom: 25px;
}

.article-details__content-004-005__pb-25 {
  padding-bottom: 25px;
}

.article-details__content-004-005__pt-25 {
  padding-top: 25px;
}

.article-details__content-004-005__pb-25-sp {
  padding-bottom: 25px;
}

@media (min-width: 768px) {
  .article-details__content-004-005__pb-25-sp {
    padding-bottom: 0;
  }
}

.article-details__content-004-005__pb-48 {
  padding-bottom: 24px;
}

@media (min-width: 768px) {
  .article-details__content-004-005__pb-48 {
    padding-bottom: 48px;
  }
}

.article-details__content-004-005__text-p {
  color: black;
  margin: 0 !important;
  font-size: 14px;
}

.article-details__content-004-005__text-p span {
  padding-right: 0;
}

@media (min-width: 768px) {
  .article-details__content-004-005__text-p span {
    padding-right: 48px;
    line-height: 2 !important;
  }
}

@media (min-width: 768px) {
  .article-details__content-004-005__text-p {
    font-size: 16px;
    line-height: 2 !important;
  }
}

.article-details__content-004-005__text-description {
  color: black !important;
  font-weight: 400;
  font-size: 14px;
}

@media (min-width: 768px) {
  .article-details__content-004-005__text-description {
    font-size: 16px;
    line-height: 2 !important;
  }
}

.article-details__content-004-005__img-2 img {
  width: 100%;
  max-width: 333px;
  align-self: center;
}

.article-details__customer-guarantees {
  border: 1px solid #e3e3e3;
  padding: 3rem 1.5rem;
  align-items: center;
  gap: 0;
  color: black;
  display: flex;
  flex-direction: column;
  margin: 48px 0 62px 0;
}

@media (min-width: 768px) {
  .article-details__customer-guarantees {
    padding: 48px;
    gap: 24px;
  }
}

.article-details__customer-guarantees-flex {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

@media (min-width: 768px) {
  .article-details__customer-guarantees-flex {
    flex-direction: row;
  }
}

.article-details__customer-guarantees-flex-text {
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  .article-details__customer-guarantees-flex-text {
    max-width: 75%;
  }
}

.article-details__customer-guarantees-flex-text h3 {
  font-size: 16px;
  font-weight: 600 !important;
  text-align: center;
  margin-block: 0 16px;
}

@media (min-width: 768px) {
  .article-details__customer-guarantees-flex-text h3 {
    font-size: 18px;
    text-align: left;
  }
}

.article-details__customer-guarantees-flex img {
  max-width: 97px;
  height: auto;
}

@media (min-width: 768px) {
  .article-details__customer-guarantees-flex img {
    max-width: 183px;
  }
}

.article-details__img-content {
  padding-top: 0.7rem !important;
}
